.bhs_header__logo{
    background-image: url(./../../assets/logoStampV2.png);
    height: 145px;
    width: 110px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    
}

@media only screen 
and (min-width : 768px) {
    .bhs_header__logo{
        background-image: url(./../../assets/logBannerV2.png);
        height: 129px;
        width: 529px;
        background-repeat: no-repeat;
    }
    
}
