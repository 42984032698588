:root {
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
}

.box {
  --border-thin: var(--s-5);

  /* ↓ Padding set to the first point on the modular scale */
  padding: var(--s1);
  /* ↓ Assumes you have a --border-thin var */
  /* border: var(--border-thin) solid; */


  /* ↓ Always apply the transparent outline, for high contrast mode */
  outline: var(--border-thin) transparent;
  outline-offset: calc(var(--border-thin) * -1);
  /* ↓ The light and dark color vars */
  --color-light: #fff;
  --color-dark: #000;
  color: var(--color-dark);
  background-color: var(--color-light);
}

.box * {
  /* ↓ Force colors to inherit from the parent
  and honor inversion (below) */
  color: inherit;
}
.box.invert {
  /* ↓ The color vars inverted */
  color: var(--color-light);
}

.stack {
    /* ↓ The space is set as a custom property */
    --space: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    }
    .stack > * {
    /* ↓ Any extant vertical margins are removed */
    margin-top: 0;
    margin-bottom: 0;
    }
    .stack > * + * {
    /* ↓ Top margin is only applied to successive elements */
    margin-top: var(--space);
}

.switcher > * {
  /* ↓ The space value; the first point on the modular scale */
  --space: var(--s1);
  /* ↓ Establish the flex context and allow wrapping */
  display: flex;
  flex-wrap: wrap;
  /* ↓ Negate the margin to follow
  (`* -1` makes the value negative) */
  margin: calc((var(--space) / 2) * -1);
}

.cluster {
    /* ↓ Set the space as a variable */
    --space: 1rem;
  }
  .cluster > * {
    /* ↓ Establish the flex context and allow wrapping */
    display: flex;
    flex-wrap: wrap;
    /* ↓ Horizontal alignment */
    justify-content: flex-start;
    /* ↓ Vertical alignment */
    align-items: center;
    /* ↓ Negate the margin to follow
    (`* -1` makes the value negative) */
    margin: calc(var(--space) / 2 * -1);
  }
  .cluster > * > * {
    /* ↓ Half margins meet to make the whole space value together */
    margin: calc(var(--space) / 2);
  }

.switcher > * > * {
  /* ↓ Allow growing, to take up available space */
  flex-grow: 1;
  /* ↓ Create the container-based breakpoint
  (returns a negative or (large) positive value */
  flex-basis: calc((var(--measure) - (100% - var(--space))) * 999);
  /* ↓ Half margins meet to make the whole space value together */
  margin: calc(var(--space) / 2);
}
.switcher > * > :nth-last-child(n+5),
.switcher > * > :nth-last-child(n+5) ~ * {
  /* Switch to a vertical configuration if
  there are more than 4 child elements */
  flex-basis: 100%;
}

.sidebar {
  /* ↓ Suppresses scrolling sometimes caused
  by the overflow */
  overflow: hidden;
}
.sidebar > * {
  /* ↓ The space value; the first point on the modular scale */
  --space: var(--s1);
  /* ↓ Establish the flex context and allow wrapping */
  display: flex;
  flex-wrap: wrap;
  /* ↓ Negate the margin to follow
  (`* -1` makes the value negative) */
  margin: calc(var(--space) / 2 * -1);
}
.sidebar > * > * {
  /* ↓ Half margins meet to make the whole space value together */
  margin: calc(var(--space) / 2);
  /* ↓ Allow growing (needed for the vertical configuration) */
  flex-grow: 1;
}

.sidebar > * > :last-child {
  /* ↓ Grow from nothing */
  flex-basis: 0;
  /* ↓ Ensure all the available space is taken */
  flex-grow: 999;
  /* ↓ The value at which the configuration changes
  between horizontal and vertical */
  min-width: calc(50% - var(--space));
}